import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
// import { Container } from "reactstrap"
import Swiper from "@components/swiper/swiper"
import PodCastSwiper from "@components/swiper/swiper"
import WebriQForm from "@webriq/gatsby-webriq-form"
import Hero from "@components/hero"
import Moment from "react-moment"
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    const podcasts = this.props.data.allSanityPodcast.edges
    const trainings = this.props.data.allSanityTraning.edges
    const events = this.props.data.allSanityEvents.edges
    // console.log(podcasts)
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        hero={<Hero location={this.props.location} />}
      >
        <SEO title="Home" description={siteDescription} />{" "}
        <section id="services" className="component pt-0">
          <div className="inner-services-container">
            <div className="container mb-5 pb-5">
              <h4 className="text-orange text-center">
                The NARM Training Institute provides a wide variety of clinical
                and professional development trainings, both in-person and
                online. Join us in building an international community of
                specialists addressing Complex Trauma.
              </h4>
            </div>

            <div className="container px-5 px-md-3">
              <div className="row justify-content-end align-items-center mb-4">
                <div className="col-md-7 pr-md-4 mb-4">
                  <div
                    className="lead-img"
                    style={{
                      backgroundImage: `url(/assets/img/narmmethod.jpg)`,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <div className="content text-white">
                    <h3 className="mt-0 text-narmblue">
                      NARM Therapist Training
                    </h3>
                    <p className="text-orange">
                      In-person advanced clinical training for the healing of
                      Complex Trauma
                    </p>
                    <p className="mt-4 d-flex flex-column flex-sm-row justify-content-start">
                      <Link
                        className="btn btn-primary inverted shadow arrow"
                        to="/trainings"
                      >
                        LEARN MORE
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-start align-items-center mb-4">
                <div className="col-md-7 order-md-2 pl-md-4 mb-4">
                  <div
                    className="lead-img"
                    style={{
                      backgroundImage: `url(/assets/img/obt.jpg)`,
                    }}
                  />
                </div>
                <div className="col-md-4 order-md-1">
                  <div className="content text-white text-md-right">
                    <h3 className="mt-0 text-narmblue">
                      Online NARM Basics Training
                    </h3>
                    <p className="text-orange">
                      Online training on the changing field of trauma, the
                      impact of Adverse Childhood Experiences (ACEs), and a
                      NARM-Informed approach for resolving C-PTSD.
                    </p>
                    <p className="mt-4 d-flex flex-column flex-sm-row justify-content-md-end">
                      <Link
                        className="btn btn-primary inverted shadow arrow"
                        to="/obt2020"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LEARN MORE
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-end align-items-center mb-4">
                <div className="col-md-7 pr-md-4 mb-4">
                  <div
                    className="lead-img"
                    style={{
                      backgroundImage: `url(/assets/img/inner-circle.jpg)`,
                    }}
                  />
                </div>
                <div className="col-md-4">
                  <div className="content text-narmblue">
                    <h3 className="mt-0">The NARM Inner Circle </h3>
                    <p className="text-orange">
                      Online learning program to support professionals in
                      gaining essential trauma-informed resources for applying
                      NARM. Join us in this wonderful online community to
                      connect, learn and share.
                    </p>
                    <p className="mt-4 d-flex flex-column flex-sm-row justify-content-start">
                      <Link
                        className="btn btn-primary inverted shadow arrow"
                        to="/online-learning/inner-circle"
                      >
                        Join the Inner Circle
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        <section id="services" className="component p-0 d-none">
          <div className="container-fluid px-md-0">
            <div className="row mx-0">
              <div className="col-md-7 px-0">
                <div className="row mx-0">
                  <div className="col-md-6 px-0">
                    <div id="inner-circle" className="services-item">
                      <div className="inner-cirlce-container">
                        <div className="content">
                          <div className="lead-img mb-3">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img/001-connection.svg"
                              width={60}
                              height={60}
                              alt="inner circle"
                            />
                          </div>
                          <div className="lead-body">
                            <h4 className="mt-0">The NARM Inner Circle </h4>
                            <p>
                              Online membership program where the global NARM
                              community comes together to connect, learn and
                              share. Essential resources for learning to apply
                              the NARM method.
                            </p>
                            <p className="mt-4">
                              <a className="btn btn-link text-white" href="#">
                                <span>Join the Inner Circle</span>
                                <LazyLoadImage
                                  effect="blur"
                                  className="ml-2 shadow"
                                  src="/assets/img/play-button.svg"
                                  width={35}
                                  height={35}
                                  alt="more"
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 px-0">
                    <div id="online-training" className="services-item">
                      <div className="online-training-container">
                        <div className="content">
                          <div className="lead-img mb-3">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img/002-book.svg"
                              width={60}
                              height={60}
                              alt="inner circle"
                            />
                          </div>
                          <div className="lead-body">
                            <h4 className="mt-0">
                              Online NARM Basics Training
                            </h4>
                            <p>
                              Live online training on the changing field of
                              trauma and the impacts of Adverse Childhood
                              Experiences (ACEs) and C-PTSD.
                            </p>
                            <p className="mt-4">
                              <a className="btn btn-link text-white" href="#">
                                <span>Get Trained</span>
                                <LazyLoadImage
                                  effect="blur"
                                  className="ml-2 shadow"
                                  src="/assets/img/play-button.svg"
                                  width={35}
                                  height={35}
                                  alt="more"
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 px-0">
                    <div id="narm-method" className="services-item">
                      <div className="narm-method-container">
                        <div className="content">
                          <div className="lead-img mb-3">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img/003-innovation.svg"
                              width={60}
                              height={60}
                              alt="inner circle"
                            />
                          </div>
                          <div className="lead-body">
                            <h4 className="mt-0">The NARM Method</h4>
                            <p>
                              The provision of in-person training in the NARM
                              method to mental health practitioners. Trainings
                              are held in four 4.5-day modules.
                            </p>
                            <p className="mt-4">
                              <a className="btn btn-link text-white" href="#">
                                <span>Sign Up Today</span>
                                <LazyLoadImage
                                  effect="blur"
                                  className="ml-2 shadow"
                                  src="/assets/img/play-button.svg"
                                  width={35}
                                  height={35}
                                  alt="more"
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5 px-0">
                <div id="here-narm" className="h-100">
                  <div className="content">
                    <LazyLoadImage
                      effect="blur"
                      className="mb-4 img-fluid"
                      src="/assets/img/logo.png"
                    />
                    <p className="font-weight-medium">
                      The NARM Training Institute offers{" "}
                      <strong>
                        online and in-person clinical training for professionals
                        who work with Complex Trauma
                      </strong>{" "}
                      such as mental health professionals, substance abuse and
                      addiction counselors, doctors, nurses, other medical
                      professionals, first responders, bodyworkers, educators,
                      coaches, spiritual and religious counselors, and more. How
                      can we help you?
                    </p>
                    <p className="mt-4 d-flex flex-column flex-sm-row justify-content-start">
                      <Link
                        className="btn btn-primary shadow arrow"
                        to="/what-is-narm"
                      >
                        Read More
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        <section id="about-us" class="component">
          <div class="container px-5 px-md-3">
            <div className="row flex-column-reverse flex-md-row justify-content-end align-items-center mb-4">
              <div className="col-md-4">
                <div id="here-narm" className="h-100">
                  <div className="content">
                    <LazyLoadImage
                      effect="blur"
                      className="mb-4 img-fluid"
                      src="/assets/img/logo.png"
                    />
                  </div>
                  <p className="font-weight-medium">
                    The NARM Training Institute provides online and in-person
                    training for professionals who work with Complex Trauma,
                    including mental health professionals, substance abuse and
                    addiction counselors, healthcare providers, first
                    responders, educators, bodyworkers, coaches, spiritual and
                    religious counselors, and more.
                  </p>
                  <p className="mt-4 d-flex flex-column flex-sm-row justify-content-start">
                    <Link
                      className="btn btn-primary shadow arrow"
                      to="/what-is-narm"
                    >
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-md-7 pr-md-4 mb-4">
                {/* <div
                  className="lead-img"
                  style={{
                    backgroundImage: `url(/assets/img/inner-circle.jpg)`,
                  }}
                /> */}
                <LazyLoadImage
                  effect="blur"
                  className="mb-4 img-fluid"
                  src="/assets/img/training-institure-team-pic.jpg"
                  style={{ borderRadius: `10px` }}
                />
              </div>
            </div>

            {/* <div className="row">
              <div className="col-md-5">
                <div id="here-narm" className="h-100">
                  <div className="content">
                    <LazyLoadImage
                      effect="blur"
                      className="mb-4 img-fluid"
                      src="/assets/img/logo.png"
                    />
                  </div>
                  <p className="font-weight-medium">
                    The NARM Training Institute provides online and in-person
                    training for professionals who work with Complex Trauma,
                    including mental health professionals, substance abuse and
                    addiction counselors, healthcare providers, first
                    responders, educators, bodyworkers, coaches, spiritual and
                    religious counselors, and more.
                  </p>
                  <p className="mt-4 d-flex flex-column flex-sm-row justify-content-start">
                    <Link
                      className="btn btn-primary shadow arrow"
                      to="/what-is-narm"
                    >
                      Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div> */}
          </div>{" "}
          {/* <div className="col-md-6 mt-4 mt-md-0" id="aboug-bg-container">
            <LazyLoadImage effect="blur" className="mb-4 img-fluid" src="" />
            <div
              className="about-bg"
              style={{
                backgroundImage: `url(/assets/img/training-institure-team-pic.jpg)`,
                backgroundSize: `cover`,
              }}
            />{" "}
          </div>{" "} */}
        </section>{" "}
        <section id="podcast" className="component py-0">
          <div className="podcast-inner-container component bg-primary">
            <div className="container px-5 px-md-3">
              <div className="intro mb-5 text-center">
                <h2 className="text-white mb-0">Transforming Trauma Podcast</h2>
                <h3 className="text-white mt-0">
                  Healing Complex Trauma as a vehicle for personal and social
                  transformation.
                </h3>

                <p className="text-white mt-3">
                  Providing you with a map for increased resiliency, healthier
                  relationships, personal growth and social change.
                </p>
              </div>
              <LazyLoadComponent>
                <PodCastSwiper
                  slidesPerView={1}
                  spaceBetween={50}
                  breakpoints={{
                    992: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {podcasts.map(({ node }) => (
                    <div className="swiper-slide px-1">
                      <div className="card shadow h-100 p-3">
                        <div className="card-heading mb-3">
                          <img
                            className="img-fluid mb-4"
                            src={node.mainImage.asset.fluid.src}
                          />
                          <iframe
                            title={node.title}
                            style={{ border: "none" }}
                            src={node.podcastUrl}
                            height={90}
                            width="100%"
                            scrolling="no"
                            allowFullScreen
                            webkitallowfullscreen
                            mozallowfullscreen
                            oallowfullscreen
                            msallowfullscreen
                          />
                        </div>
                        <div className="card-body px-0 pb-0">
                          <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                            {node.subtitle}
                          </h6>
                          <h5 className="mt-0">
                            <Link
                              to={`/transformingtrauma/${node.slug.current}`}
                            >
                              {node.title.length <= 80
                                ? node.title
                                : node.title.slice(0, 80) + "..."}
                            </Link>
                          </h5>
                          <p>{node.excerpt.slice(0, 80)}...</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </PodCastSwiper>
              </LazyLoadComponent>
              <div className="row">
                <div className="col-md-6 px-md-4 mb-4" />
                <div className="col-md-6 px-md-4 mb-4" />
              </div>
              <p className="d-flex flex-column flex-sm-row justify-content-center mt-4">
                <Link
                  className="btn btn-primary btn-white-border shadow mr-sm-3 mb-3 mb-sm-0 arrow"
                  to="/transformingtrauma"
                >
                  View More
                </Link>
                <a
                  className="btn btn-primary inverted shadow arrow"
                  href="#join-newsletter"
                >
                  Subscribe
                </a>
              </p>
            </div>
          </div>
        </section>
        <section
          id="community-event"
          className="component"
          style={{
            backgroundImage: 'url("/assets/img/events.jpg")',
          }}
        >
          <div className="container px-5 px-md-3">
            <div className="intro text-center mb-5">
              <h2 className="text-orange">
                NARM Trainings <span className="text-primary">and</span> Events
              </h2>
            </div>
            <div className="event-list-container">
              <div className="row flex-column">
                {events.map((event, index) => (
                  <div
                    className="col-md-8 mb-4 event-list"
                    id={`event-${index}`}
                    key={index}
                  >
                    <div className="event-container shadow">
                      <div className="d-flex event row">
                        <div className="text-center event-created col-sm-3">
                          <h5 className="text-uppercase my-0 text-white">
                            <Moment format="MMM">
                              {event.node.publishedDate}
                            </Moment>
                          </h5>
                          <h2 className="my-0 text-white">
                            <Moment format="DD">
                              {event.node.publishedDate}
                            </Moment>
                          </h2>
                          <h5 className="text-uppercase my-0 text-white">
                            <Moment format="ddd">
                              {event.node.publishedDate}
                            </Moment>
                          </h5>
                        </div>
                        <div className="event-content col-sm-9">
                          <h5 className="mb-4">
                            <Link to={`/events/${event.node.slug.current}`}>
                              {event.node.title}
                            </Link>
                          </h5>

                          <p>
                            {event.node.shortDescription.slice(0, 200)}
                            ...
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <p className="d-flex flex-column flex-sm-row justify-content-center">
              <Link className="btn btn-primary shadow arrow" to="/events">
                View More Events
              </Link>
            </p>
          </div>{" "}
        </section>{" "}
        <section id="training" className="component bg-light">
          <div className="container px-5 px-md-3">
            <div className="intro mb-4">
              <div className="row">
                <div className="col-md-9 mx-auto text-center">
                  <h2 className="text-primary">
                    <span className="text-orange">NARM</span> Therapist Training
                  </h2>
                  <p className="text-muted">
                    {/* Join us for this in-person{" "}
                    <strong>
                      advanced clinical training designed to enhance the skills
                      of professionals who work with developmental trauma.
                    </strong>{" "}
                    Intended for mental health practitioners with an active
                    practice so the material presented in each module can be
                    applied and developed */}
                    Join us for advanced clinical training designed to enhance
                    the skills of mental health professionals who work with the
                    impact of Adverse Childhood Experience (ACEs) and Complex
                    Trauma (C-PTSD).
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {trainings.map(({ node }) => (
                <div key={node.id} className="col-md-6 col-lg-4 mb-4">
                  <div className="card shadow h-100">
                    <div
                      className="card-img"
                      style={{
                        backgroundImage: `url(${
                          node.mainBanner !== null
                            ? node.mainBanner.asset.fluid.src
                            : "https://placeimg.com/600/400/nature"
                        })`,
                      }}
                    >
                      <div className="card-link">
                        <Link
                          to={
                            node.slug !== null
                              ? "/trainings/" + node.slug.current
                              : ""
                          }
                        >
                          <LazyLoadImage
                            effect="blur"
                            src="/assets/img/play.svg"
                            width={14}
                            height={14}
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="card-body">
                      <h6 className="text-uppercase card-subtitle mb-2 font-weight-bold text-orange">
                        {node.area}
                      </h6>
                      <h5 className="card-title text-primary">
                        {node.title ==
                        "The NARM Practitioner Training for Attachment, Relational and Developmental Trauma"
                          ? "The NARM Practitioner Training"
                          : node.title}
                      </h5>
                      <div className="card-created">
                        <p className="text-muted mb-0">
                          <LazyLoadImage
                            effect="blur"
                            src="/assets/img/calendar.svg"
                            width={20}
                            height={20}
                          />
                          <span className="ml-2">{node.beginningDate}</span>
                        </p>
                      </div>
                    </div>
                    <Link
                      className="hidden-link"
                      to={
                        node.slug !== null
                          ? "/trainings/" + node.slug.current
                          : ""
                      }
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <p className="d-flex flex-column flex-sm-row justify-content-center mt-4">
              <Link
                className="btn btn-primary shadow mr-sm-3 mb-3 mb-sm-0 arrow"
                to="/schedule"
              >
                View More
              </Link>
              <a
                className="btn btn-primary inverted shadow arrow"
                href="https://drlaurenceheller.com/narm-training/"
                target="_blank"
                rel="noopener noreferrer"
              >
                International Trainings
              </a>
            </p>
          </div>{" "}
        </section>{" "}
        <section
          id="testimonials"
          className="component pt-0 pb-0"
          style={{ paddingBottom: "100px" }}
        >
          <div className="testimonials-inner-container bg-primary py-5">
            <div className="container px-5 px-md-3">
              <div className="intro mb-5">
                <h2 className="text-white">Testimonials</h2>
                <p className="text-white">
                  What do our practitioners think of our Training?
                </p>
              </div>
              <div className="row">
                <div className="col-md-11">
                  <Swiper slidesPerView={1}>
                    <div className="swiper-slide">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="testimonial-img text-right">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img//Jamal-Granick-PhD-LMFT-150x150.jpg"
                              alt="Jamal-Granick"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="testimonial-content">
                            <p className="text-white">
                              Laurence Heller’s NeuroAffective Relational Model
                              (NARM) is among the very best training I have
                              encountered. NARM is both an integrative and
                              comprehensive approach… the material is presented
                              in a way that is digestible and immediately
                              applicable in clinical practice. I found it
                              particularly useful that NARM is presented as a
                              set of principles, rather than a set of protocols
                              to be memorized. This empowers students to trust
                              their own creativity and clinical intuition while
                              significantly expanding their conceptual framework
                              and skills. Perhaps most valuable (and moving) to
                              me is the deep respect NARM has for clients’ own
                              capacity to heal, which is inherent in the model.
                              This training has contributed enormously to my
                              personal and professional development.
                            </p>
                            <h4 className="text-white-50">Jamal Granick</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="testimonial-img text-right">
                            <LazyLoadImage
                              effect="blur"
                              src="/assets/img//Screen-Shot-2018-01-04-at-10.36.32-PM-768x702-150x150.png"
                              alt="Suzie Gruber"
                            />
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="testimonial-content">
                            <p className="text-white">
                              Attending the NARM training was one of the best
                              personal and professional decisions I have ever
                              made. In my opinion, NARM provides the missing
                              piece for the trauma practitioner’s toolbox,
                              enabling us to skillfully support people in
                              navigating the complexities of developmental
                              trauma by marrying the cognitive and the somatic
                              and thereby acknowledging each client as a whole,
                              fully empowered adult. I find myself enjoying my
                              work in private practice more than ever as I
                              witness my clients come alive while they
                              deconstruct lifelong patterns which recede into
                              the past as they experience a new level of
                              presence and agency in the here and now.
                            </p>
                            <h4 className="text-white-50">Suzie Gruber</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        <section
          id="join-newsletter"
          className="component"
          style={{ paddingTop: "160px" }}
        >
          <div className="join-newsletter-inner">
            <div className="container px-5 px-md-3">
              <div className="intro mb-4">
                <div className="row">
                  <div className="col-md-9 text-center mx-auto">
                    <h2 className="text-orange">
                      Join Our 
                      <span className="text-primary">Newsletter</span>
                    </h2>
                    <p className="text-muted">
                      Subscribe today to get the latest news, updates and
                      special offers delivered directly to your inbox.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 mx-auto">
                  <WebriQForm
                    id="subscription"
                    method="POST"
                    data-form-id="e6192cae-b02e-40b2-9b1b-a09248a699ac"
                    name="Subscription Form"
                  >
                    <div className="form-group align-items-center row m-0">
                      <div className="col-md px-0 mb-3 mb-md-0">
                        <input
                          className="form-control mb-md-0"
                          type="text"
                          placeholder="Enter your email address"
                          name="Email"
                        />
                      </div>
                      <div className="col-md-auto px-0">
                        <p className="d-flex flex-column flex-sm-row justify-content-start justify-content-md-center mb-0">
                          <button
                            className="btn btn-primary float-sm-right arrow"
                            type="submit"
                          >
                            Subscribe now
                          </button>
                        </p>
                      </div>
                    </div>
                  </WebriQForm>
                </div>
              </div>
            </div>
          </div>{" "}
        </section>{" "}
        <section id="books-online" className="component pt-3">
          <div className="container px-5 px-md-3">
            <div className="text-center intro mb-5">
              <h2 className="text-orange">
                Healing <span className="text-primary">Developmental</span>{" "}
                Trauma
              </h2>
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="col-md-3 mb-4">
                <LazyLoadImage
                  effect="blur"
                  className="img-fluid"
                  src="/assets/img/book2.jpg"
                />
              </div>
              <div className="col-md-6">
                <div className="content">
                  <h4>
                    Healing Developmental Trauma: How Early Trauma Affects
                    Self-Regulation, Self-Image, and the Capacity for
                    Relationship
                  </h4>
                  <p>
                    Read the influential book that introduces NARM and has been
                    translated into 15 languages – inspiring practitioners
                    around the world to make NARM part of their healing
                    ‘toolkit’. The book and audiobook are available on{" "}
                    <a
                      href="https://www.amazon.com/dp/1583944893/ref=asc_df_15839448935679709?tag=shopz0d-20&ascsubtag=shopzilla_mp_1498-20&15425142688980293950810080302008005&creative=395261&creativeASIN=1583944893&linkCode=asn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Amazon
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.barnesandnoble.com/w/healing-developmental-trauma-laurence-heller/1110981083;jsessionid=366F1C89D5B86B5BA2F6F3FA1861A029.prodny_store02-atgap13?ean=9781583944899&st=AFF&2sid=VigLink_6161663_NA&sourceId=AFFVigLink#/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      other online booksellers
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <p className="d-flex flex-column flex-sm-row justify-content-center mt-5">
              <a
                className="btn btn-primary shadow mr-sm-3 mb-3 mb-sm-0 arrow"
                href="https://www.amazon.com/dp/1583944893/ref=asc_df_15839448935679709?tag=shopz0d-20&ascsubtag=shopzilla_mp_1498-20&15425142688980293950810080302008005&creative=395261&creativeASIN=1583944893&linkCode=asn"
                target="_blank"
                rel="noopener noreferrer"
              >
                BUY THE BOOK
              </a>
            </p>
          </div>{" "}
        </section>{" "}
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPodcast(sort: { order: ASC, fields: publishedAt }, limit: 6) {
      edges {
        node {
          id
          title
          subtitle
          podcastUrl
          slug {
            current
          }
          excerpt
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          host {
            id
            name
            image {
              asset {
                fixed(width: 50, height: 50) {
                  src
                }
              }
            }
          }
        }
      }
    }
    allSanityTraning(sort: { order: ASC, fields: beginningDate }, limit: 6) {
      edges {
        node {
          id
          title
          beginningDate(locale: "US", formatString: "MMM-DD-YYYY")
          applyNowLink
          area
          slug {
            current
          }
          mainBanner {
            asset {
              fluid {
                src
              }
            }
          }
        }
      }
    }
    allSanityEvents(sort: { order: DESC, fields: publishedDate }, limit: 3) {
      edges {
        node {
          id
          title
          subTitle
          shortDescription
          publishedDate(locale: "US")
          slug {
            current
          }
        }
      }
    }
  }
`
